import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import Cookies from "js-cookie";
import { Loading } from "../components/Loading";
import Nav from "../components/navbar";
import { AccountList } from "../utils/types";
import { getAccountsList } from "../utils/API_calls";

type SupplierList = {
  id: number;
  name: string;
  tin: string;
  phone_number: string;
  location: string;
};

type CustomerList = {
  balance: number;
  customerId: string;
  customerName: string;
  totalIn: string;
  totalOrder: string;
};

const PaymentsScreen = () => {
  const [amount, setAmount] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<SupplierList[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [sellerIsCustomer, setSellerIsCustomer] = useState<boolean>(true);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("1");

  const today = new Date();
  today.setDate(today.getDate());

  const [invoiceDate, setInvoiceDate] = useState<string>(today.toISOString());

  const toast = useToast();

  useEffect(() => {
    getCustomerList();
    retrieveAccounts();
  }, []);

  const getCustomerList = async () => {
    setIsLoading(true);
    const headers = await getHeadersForRequest();
    axios
      .get<CustomerList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/customerBalances`,
        {
          headers,
        }
      )
      .then((response) => {
        // Sort filtered customers by balance in descending order
        const sortedFilteredData = response.data.sort(
          (a, b) => Number(b.balance) - Number(a.balance)
        );
        setCustomers(sortedFilteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const retrieveAccounts = async () => {
    setIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setIsLoading(false);
  };

  const handleSaveData = async () => {
    const email = Cookies.get("emailAddress");
    // Save listItems to database
    const data = {
      supplierId: sellerIsCustomer
        ? null
        : selectedSupplier === ""
        ? null
        : selectedSupplier,
      customerId: sellerIsCustomer ? selectedCustomer : null,
      paymentDate: invoiceDate,
      userId: email,
      totalAmount: amount,
      paymentType: sellerIsCustomer ? 1 : 2,
      transactionDate: new Date(),
      notes: notes,
      account: selectedAccount,
    };

    setIsLoading(true);

    if (selectedCustomer === "" || selectedAccount === "") {
      toast({
        title: "Please fill all fields!",
        status: "error",
      });
      return;
    }

    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/payments/save/sale`,
        data,
        { headers }
      );
      console.log(response.data);
      setIsLoading(false);
      toast({
        title: "Payment Details Saved!",
        status: "success",
      });
      getCustomerList();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      getCustomerList();
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav />
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            p={6}
            m="10px auto"
            as="form"
          >
            <Stack px={5} alignItems={"center"} pb={10} flex={1}>
              <Text fontSize="lg" fontFamily="">
                Amount
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    inputMode="numeric"
                  />
                </Stack>
              </Stack>

              <Text fontSize="lg" fontFamily="">
                Pick A Customer
              </Text>
              <Stack px={6} width={"300"}>
                <Select
                  minW={"300"}
                  flex={1}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                >
                  <option value={""}>Select Customer</option>
                  {customers.map((customer) => (
                    <option value={customer.customerId.toString()}>
                      {customer.balance === 0
                        ? customer.customerName
                        : customer.customerName +
                          " - " +
                          numberWithCommas(customer.balance)}
                    </option>
                  ))}
                </Select>
              </Stack>

              <Stack width={"300"}>
                <Text>Account</Text>
                <Select
                  onChange={(e) => setSelectedAccount(e.target.value)}
                  minW={"300"}
                >
                  <option value={""}>Select Account</option>
                  {accounts.map((account) => (
                    <option value={account.id.toString()}>
                      {account.name}
                    </option>
                  ))}
                </Select>
              </Stack>

              <Text fontSize="lg" fontFamily="">
                Notes
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Stack>
              </Stack>

              <Text fontSize="lg" fontFamily="">
                Date
              </Text>

              <Stack>
                <Input
                  minW={"300"}
                  placeholder="Start Date"
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                />
              </Stack>
            </Stack>

            <Stack alignItems={"center"}>
              <Button
                variant={"solid"}
                onClick={() => handleSaveData()}
                borderRadius={20}
                width={200}
              >
                <Text>Save</Text>
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default PaymentsScreen;
