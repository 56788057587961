import { useEffect, useState, useRef } from "react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import moment from "moment";
import Cookies from "js-cookie";
import SignatureCanvas from "react-signature-canvas";

const Settings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [logins, setLogins] = useState<[]>([]);
  const [selectedLogin, setSelectedLogin] = useState<number>(0);
  const [signature, setSignature] = useState<string | null>(null);
  const toast = useToast();
  const deviceIdString = Cookies.get("deviceId");
  const loggedInDeviceId = deviceIdString ? parseInt(deviceIdString) : 0;
  const sigCanvas = useRef<any>(); // Reference to the signature canvas
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false); // Control modal visibility
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    setIsLoading(true);
    getSignature();
  }, []);

  const saveSignature = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { signature: signatureData },
        { headers }
      );

      if (response.status === 200) {
        console.log("Signature saved successfully");
        setIsSignatureModalOpen(false);
        setModalIsLoading(false);
        getSignature();
      } else {
        console.log("Error saving signature");

        setModalIsLoading(false);
      }
    } catch (error) {
      console.error(error);

      setModalIsLoading(false);
    }
  };

  const getSignature = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { headers }
      );
      console.log(response.data.signature);
      if (response.status === 200) {
        setSignature(response.data.signature);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Open the signature modal for customer or staff
  const openSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  // Clear signature canvas
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav />
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              {/* <Heading>
                Signature status: {signature !== null ? "Yes" : "No"}
              </Heading> */}

              <HStack>
                <Button onClick={() => openSignatureModal()}>
                  {signature !== null ? "Update Signature" : "Add Signature"}
                </Button>
                {signature !== null && (
                  <Stack
                    ml={3}
                    bg="white"
                    borderRadius={"10"}
                    borderWidth={1}
                    borderColor={"black"}
                  >
                    <Image h={50} w={150} src={signature} alt="Signature" />
                  </Stack>
                )}
              </HStack>

              {/* <Stack bg="white" h={250} w={"50%"}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    // minWidth : 0.5,
                    // width: 0.5,
                    height: 500,
                    className: "sigCanvas",
                  }}
                />
              </Stack> */}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Modal
        isOpen={isSignatureModalOpen}
        onClose={() => setIsSignatureModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Signature</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              borderWidth={1}
              borderColor={colorMode === "light" ? "black" : "white"}
              borderRadius={10}
              bg={"white"}
              w={"100%"}
              // h={250}
            >
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  height: 500,
                  className: "sigCanvas",
                }}
              />
            </Stack>

            <ModalFooter>
              <Button onClick={clearSignature} mr={3}>
                Clear
              </Button>
              <Button isLoading={modalIsLoading} onClick={saveSignature}>
                Save Signature
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Settings;
