import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { Loading } from "../components/Loading";
import moment from "moment";
import QRCode, { ByteData } from "qrcode";
import Nav from "../components/navbar";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCodeComponent from "../components/QRCodeComponent";
import SignatureCanvas from "react-signature-canvas";

const logo = require("../resources/images/logo.jpg");
const logo2 = require("../resources/images/koas-logo.png");

type OrderList = {
  id: number;
  description: string;
  qty: string;
  price: string;
  transactionNumber: number;
  userId: string;
  transactionDate: Date;
  totalAmount: number;
  customerId: number;
  paymentStatus: number;
  paymentDate: Date;
  deliveryStatus: boolean;
  deliveryDate: Date;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  transactionType: number;
  supplierId: number;
  invoiceDate: Date;
  isVoid: boolean;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  traCode: string;
  traTime: string;
  QRLink: string;
  traReceiptNumber: string;
  refInvoice: string;
  signature: string;
};

interface ExpenseDetailsScreenProps {
  ID: string;
}

const ExpenseDetailsScreen: React.FC<ExpenseDetailsScreenProps> = ({ ID }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderList[]>([]);
  const [resourceFound, setResourceFound] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const { colorMode, toggleColorMode } = useColorMode();

  const { orderId } = useParams<{ orderId: string }>();

  const logo1 = require("../resources/images/logo1.png");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [supplierSignature, setSupplierSignature] = useState<string | null>(
    null
  ); // Customer signature
  const [staffSignature, setStaffSignature] = useState<string | null>(null); // Staff signature
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false); // Control modal visibility
  const [activeSigner, setActiveSigner] = useState<"supplier" | "staff">(
    "supplier"
  ); // Track who is signing

  const sigCanvas = useRef<any>(); // Reference to the signature canvas

  // Open the signature modal for customer or staff
  const openSignatureModal = (signer: "supplier" | "staff") => {
    setActiveSigner(signer);
    setIsSignatureModalOpen(true);
  };

  // Clear signature canvas
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  // Save the signature as base64 for the active signer
  const saveSignature = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/signature`,
        { signature: signatureData, id: orders[0].id },
        { headers }
      );

      if (response.status === 200) {
        console.log("Signature saved successfully");
        setIsSignatureModalOpen(false);
        setModalIsLoading(false);
        getOrderDetails();
      } else {
        console.log("Error saving signature");

        setModalIsLoading(false);
      }
    } catch (error) {
      console.error(error);

      setModalIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderDetails();
    getStaffSignature();
    // getSupplierSignature();
    console.log(orderId);
  }, []);

  useEffect(() => {
    checkFileExists();
  }, [orders]);

  const checkFileExists = async () => {
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/check-file/${orders[0].transactionNumber.toString()}.pdf`,
        { headers }
      );
      setIsLoading(false);
      if (response.status === 200) {
        setResourceFound(true);
      } else {
        setResourceFound(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getOrderDetails = async () => {
    setIsLoading(true);
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      orderid: orderId ? orderId : ID,
    };
    const headers = { ...oldHeaders, ...newHeaders };

    try {
      const response = await axios.get<OrderList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/orderdetails/expenses`,
        { headers }
      );
      console.log(response.data);
      setOrders(response.data);
      setIsLoading(false);
      getSupplierSignature(response.data[0].id);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const downloadResources = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting download...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${orders[0].transactionNumber}.pdf`); // File name
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up link element

      // Clean up the URL after the download completes
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error downloading the PDF:", error);
        alert(
          "An error occurred while downloading the file. Please try again."
        );
      }
    }
  };

  const viewResource = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting file view...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Open the PDF in a new tab
      window.open(fileURL);

      // Clean up the URL after the file is opened
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error viewing the PDF:", error);
        alert("An error occurred while viewing the file. Please try again.");
      }
    }
  };

  const downloadVoucher = async (data: any) => {
    setIsLoading(true);

    // Define your custom width and height (e.g., 150mm x 100mm)
    const customWidth = 10; // in mm
    const customHeight = 148; // in mm

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm", // unit: 'pt', 'mm', 'cm', 'in'
      format: "a4", //[210, 148], // custom size as [width, height]
    });

    console.log("data: " + JSON.stringify(data[0]));

    // Load an image and add it to the PDF
    const img = new Image();
    img.src = logo1;
    img.onload = async () => {
      // Get the dimensions of the image and the PDF
      const imgWidth = 30; // Adjust as needed
      const imgHeight = 30; // Adjust as needed
      const pageWidth = doc.internal.pageSize.getWidth();

      // Position the image in the top right corner
      const x = pageWidth - imgWidth - 15; // 10 units from the right edge
      const y = 20; // 10 units from the top edge

      // doc.addImage(img, "JPEG", x, y, imgWidth, imgHeight);

      // Add dynamic content
      doc.setFontSize(20);
      doc.text(`Expense Voucher`, 15, 15);
      doc.text(data[0].transactionNumber.toString(), pageWidth - 33, 15);
      doc.setFontSize(10);

      doc.text(`To: ${data[0].name ? data[0].name : "Cash"}`, 15, 25);
      doc.text(
        `Reference Invoice: ${data[0].refInvoice ? data[0].refInvoice : ""}`,
        15,
        30
      );
      doc.text(
        `Delivery Note: ${
          data[0].deliveryNoteNumber ? data[0].deliveryNoteNumber : ""
        }`,
        15,
        35
      );
      // data[0].tin && doc.text(`${data[0].tin}`, 15, 67);
      // Cookies.get("businessName") === "Keinth's Food" &&
      //   doc.text("105213395", pageWidth - 43, 58);
      doc.text(
        `${moment(data[0].transactionDate).format("DD MMM YYYY")}`,
        pageWidth - 45,
        35
      );

      // Table data
      const tableColumn = ["Description", "Amount"];
      const tableRows: any[] = [];

      const maxRows = 10;

      // Map your data and fill tableRows
      data.forEach(
        (item: { qty: number; description: string; price: number }) => {
          const tableRow = [
            item.description + "  (" + item.qty + ")",
            numberWithCommas(item.qty * item.price),
          ];
          tableRows.push(tableRow);
        }
      );

      // If the data has fewer than 10 rows, add empty rows
      while (tableRows.length < maxRows) {
        tableRows.push(["", ""]); // Push empty rows until we reach 10
      }

      let finalY = imgHeight + 30;

      console.log("doc " + JSON.stringify(tableRows));

      (doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 40, // Start table below the image
        didDrawPage: (dataArg: any) => {
          finalY = dataArg.cursor.y; // Get Y position of the end of the table
        },
        headStyles: {
          fontSize: 10,
          fillColor: "#000",
        },
        bodyStyles: {
          fontSize: 10,
          font: "helvetica",
        },
        columnStyles: {
          0: { cellWidth: "auto" }, // First column (auto width)
          1: { halign: "right", cellWidth: 20 }, // Last column (right-align and auto width)
        },
        tableWidth: "auto", // Adjust table width automatically
      });

      // Add total
      const total = `Total: ${data[0].totalAmount}`;
      const totalX = pageWidth - doc.getTextWidth(total) - 30; // Right-align the total

      doc.setFontSize(15);
      doc.text(
        `TOTAL: ${numberWithCommas(data[0].totalAmount)}`,
        pageWidth - 45,
        130
      );

      if (supplierSignature) {
        // Add the customer signature image to the PDF
        doc.addImage(supplierSignature, "PNG", 50, 138, 10, 5); // Customer signature at bottom left
        // doc.text("Customer Signature", 20, 140); // Label below signature
      }

      if (staffSignature) {
        // Add the staff signature image to the PDF
        doc.addImage(staffSignature, "PNG", pageWidth - 40, 138, 10, 5); // Staff signature at bottom right
        // doc.text("Authorized Staff Signature", pageWidth - 50, 140); // Label below signature
      }

      doc.text(`Signature: `, 15, 143);

      doc.text(`Authorized: `, pageWidth - 70, 143);

      // Generate QR code and add to PDF
      const qrCodeValue = "this is a dummy QR for testing";

      try {
        // const qrCodeDataUrl = await QRCode.toDataURL(qrCodeValue);
        // doc.addImage(qrCodeDataUrl, "PNG", 10, finalY + 20, 50, 50); // Adjust position and size as needed
        // doc.text(
        //   `${data[0].traCode}${data[0].traReceiptNumber}`,
        //   70,
        //   finalY + 30
        // );
        // doc.text(
        //   `${data[0].traTime.slice(0, 2)}:${data[0].traTime.slice(
        //     2,
        //     4
        //   )}:${data[0].traTime.slice(4)}`,
        //   70,
        //   finalY + 40
        // );
      } catch (error) {
        console.error("QR Code generation error:", error);
      }

      // Generate PDF and get URL
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setPdfUrl(pdfUrl);
      onOpen();
      setIsLoading(false);
    };
  };

  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${orders[0].transactionNumber} - ${orders[0].name}.pdf`;
      link.click();
    }
  };

  const getStaffSignature = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { headers }
      );
      // console.log(response.data.signature);
      if (response.status === 200) {
        setStaffSignature(response.data.signature);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getSupplierSignature = async (id: number) => {
    setIsLoading(true);
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      id: id,
    };
    const headers = { ...oldHeaders, ...newHeaders };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/signature`,
        { headers }
      );
      console.log(response.data.signature);
      if (response.status === 200) {
        setSupplierSignature(response.data.signature);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack>
        {ID !== "" ? null : (
          <Stack position="sticky" top={0} zIndex={1} width="full">
            <Nav />
          </Stack>
        )}
        <Stack>
          <Stack px={1}>
            {isLoading ? (
              <Center h={600}>
                <Loading />
              </Center>
            ) : (
              <>
                <Stack p={1}>
                  {orders.length > 0 && (
                    <Stack borderWidth={2} borderRadius={5}>
                      <Stack
                        alignItems={"center"}
                        pt={2}
                        position={"absolute"}
                        right={0}
                        left={0}
                      >
                        <Button
                          colorScheme="green"
                          onClick={() => {
                            downloadVoucher(orders);
                          }}
                          disabled={!supplierSignature || !staffSignature}
                        >
                          Voucher
                        </Button>
                      </Stack>
                      <HStack
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        p={1}
                        pl={3}
                      >
                        <Stack textAlign={"left"}>
                          <Text fontSize="2xl" fontFamily="">
                            PO# {orders[0].transactionNumber}
                          </Text>
                          {orders[0].refInvoice && (
                            <Text>Inv Ref: {orders[0].refInvoice}</Text>
                          )}
                          {orders[0].deliveryNoteNumber && (
                            <Text>
                              Delivery Note #{orders[0].deliveryNoteNumber}
                            </Text>
                          )}

                          <Text
                            fontSize="lg"
                            fontFamily=""
                            mb={-2}
                            py={5}
                            fontWeight={"bolder"}
                            color={orders[0].paymentStatus ? "green" : "red"}
                          >
                            {orders[0].paymentStatus ? "PAID" : "NOT PAID"}
                          </Text>
                          <Text fontSize="lg" fontFamily="" mb={-2}>
                            Supplier
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].name ? orders[0].name : "Cash"}
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].tin}
                          </Text>
                        </Stack>
                        <Stack>
                          <img
                            src={
                              Cookies.get("businessName") === "Keinth's Food"
                                ? logo
                                : logo2
                            }
                            style={{ height: 200, backgroundColor: "white" }}
                          />

                          <Text
                            mb={-2}
                            fontSize="lg"
                            fontFamily=""
                            textAlign={"right"}
                          >
                            PO Date
                          </Text>
                          <Text fontSize="lg" fontFamily="" textAlign={"right"}>
                            {moment(orders[0].invoiceDate).format(
                              "DD MMM YYYY"
                            )}
                          </Text>
                        </Stack>
                      </HStack>

                      <Stack
                        flexDir="row"
                        alignItems="center"
                        borderBottomColor={"black"}
                        borderBottomWidth={2}
                        borderTopColor={"black"}
                        borderTopWidth={2}
                      >
                        <Stack
                          flex={1}
                          alignItems={"center"}
                          borderRightWidth={1}
                          borderRightColor={"black"}
                        >
                          <Text fontSize={"lg"}>QTY</Text>
                        </Stack>

                        <Stack
                          pl={3}
                          style={{
                            flex: 6,
                          }}
                        >
                          <Text fontSize={"lg"}>DESCRIPTION</Text>
                        </Stack>
                        <Stack
                          style={{
                            flex: 2,
                            alignItems: "center",
                          }}
                        >
                          <Text fontSize={"lg"}>AMOUNT</Text>
                        </Stack>
                      </Stack>

                      {/* TO DO: IMPLEMENT THE ONE FROM NATIVE BASE */}

                      {orders.map((item, key) => (
                        <Stack
                          flexDir="row"
                          py={2}
                          alignItems="center"
                          borderBottomColor={"black"}
                          borderBottomWidth={2}
                        >
                          <Stack
                            flex={1}
                            alignItems={"center"}
                            borderRightWidth={1}
                            borderRightColor={"black"}
                          >
                            <Text fontSize={"2xl"}>{item.qty}</Text>
                          </Stack>

                          <Stack
                            pl={3}
                            style={{
                              textAlign: "left",
                              flex: 6,
                            }}
                          >
                            <Text
                              fontSize={"xl"}
                              // color={"white"}
                            >
                              {item.description}
                            </Text>
                          </Stack>
                          <Stack
                            style={{
                              flex: 2,
                              alignItems: "center",
                            }}
                          >
                            <Text fontSize={"xl"}>
                              {numberWithCommas(
                                parseInt(item.price) * parseInt(item.qty)
                              )}
                            </Text>
                          </Stack>
                        </Stack>
                      ))}

                      {/* END OF LIST */}

                      <Stack alignItems={"flex-end"} pr={3} py={2}>
                        <Text fontSize="3xl" fontFamily="">
                          TOTAL{"     "}
                          {numberWithCommas(orders[0].totalAmount)}
                        </Text>
                        {orders[0].signature === null && (
                          <Button
                            colorScheme="blue"
                            onClick={() => openSignatureModal("supplier")}
                          >
                            Add Signature
                          </Button>
                        )}
                      </Stack>

                      {resourceFound && (
                        <Text>Additional Resources Found:</Text>
                      )}

                      {resourceFound && (
                        <HStack
                          alignItems={"center"}
                          justifyContent={"space-evenly"}
                          pt={2}
                          // position={"absolute"}
                          // right={10}
                          // bottom={10}
                        >
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              viewResource();
                            }}
                          >
                            Preview
                          </Button>
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              downloadResources();
                            }}
                          >
                            Download
                          </Button>
                        </HStack>
                      )}

                      <Stack p={4} alignItems={"center"}>
                        {orders[0].traReceiptNumber === null ||
                        orders[0].traTime === null ||
                        orders[0].traReceiptNumber === "" ||
                        orders[0].traTime === "" ? null : (
                          <Stack
                            onClick={() => {
                              const order = orders[0];
                              const url = `${order.QRLink}${order.traCode}${order.traReceiptNumber}_${order.traTime}`;
                              window.open(url, "_blank");
                            }}
                          >
                            <Stack>
                              {/* <QRCode
                                value={
                                  orders[0].QRLink +
                                  orders[0].traCode +
                                  orders[0].traReceiptNumber +
                                  "_" +
                                  orders[0].traTime
                                }
                              /> */}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                {/* Preview Modal */}

                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>PDF Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {pdfUrl && (
                        <iframe
                          ref={iframeRef}
                          src={pdfUrl}
                          width="100%"
                          height="400px"
                        />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={handleDownload}
                      >
                        Download PDF
                      </Button>
                      <Button variant="ghost" onClick={onClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                {/* Signature Modal */}
                <Modal
                  isOpen={isSignatureModalOpen}
                  onClose={() => setIsSignatureModalOpen(false)}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Recipient</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Stack
                        borderWidth={1}
                        borderColor={colorMode === "light" ? "black" : "white"}
                        borderRadius={10}
                        bg={"white"}
                        w={"100%"}
                        // h={250}
                      >
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          // backgroundColor="white"
                          canvasProps={{ height: 500, className: "sigCanvas" }}
                        />
                      </Stack>

                      <ModalFooter>
                        <Button onClick={clearSignature} mr={3}>
                          Clear
                        </Button>
                        <Button
                          isLoading={modalIsLoading}
                          onClick={saveSignature}
                        >
                          Save Signature
                        </Button>
                      </ModalFooter>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExpenseDetailsScreen;
